<template>
    <div>
        <Navbar/>
         <b-container class="mt-5">
            <b-row>
                <b-col cols="12" sm="12" md="7" lg="7">
                    <h3 class="txt_secondary text-uppercase mb-3">Mi perfil</h3>
                    <b-table-simple class="table-sm">
                        <b-tbody>  
                            <b-tr>
                                <b-th class="th_style">Nombre</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2"><small>{{datos.nombre}}</small></span>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Email</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2"><small>{{datos.email}}</small></span>
                                </b-td>
                            </b-tr>
                            <b-tr>
                                <b-th class="th_style">Perfil de usuario</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2"><small>{{datos.perfil}}</small></span>
                                </b-td>
                            </b-tr>
                            <b-tr v-if="usuarioLog.perfil.id==3 || usuarioLog.perfil.id==4">
                                <b-th class="th_style">Cédula profesional</b-th>
                                <b-td class="border_table">
                                    <span class="pl-2"><small>{{datos.cedula}}</small></span>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
         </b-container>  
    </div>  
</template>

<script>
import Navbar from "@/components/Navbar.vue"
import CryptoJS from 'crypto-js'
import { mapState } from "vuex"

export default {
    name: 'Perfil',
    components: { Navbar },

    data:() => ({
        datos: {
            nombre: '',
            email: '',
            perfil: '',
            cedula: ''
        }
    }),

    computed: {
        ...mapState(['usuarioLog'])
    },

    methods: {
        getInfo() {
            this.datos.nombre = CryptoJS.AES.decrypt(""+localStorage.getItem('nombreEncrypt'),'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            this.datos.email = CryptoJS.AES.decrypt(""+localStorage.getItem('emailEncrypt'),'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            this.datos.perfil = CryptoJS.AES.decrypt(""+localStorage.getItem('perfilNombreEncrypt'),'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            this.datos.cedula =CryptoJS.AES.decrypt(""+localStorage.getItem('cedulaEncrypt'),'secret key heal4701tech').toString(CryptoJS.enc.Utf8);
            console.log(this.datos);
        }
    },

    mounted() {
        this.getInfo();
    },
    destroyed() {  
        this.$destroy();
        // this.$el.parentNode.removeChild(this.$el);
    }
}
</script>

<style scoped>

</style>